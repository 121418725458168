import { E } from "./utils";

window.onload = function () {
  const logo = E("logo") as HTMLButtonElement;
  const audio = E("logo-audio") as HTMLAudioElement;
  const spectralite = document.querySelector('#spectralite') as HTMLVideoElement;

  logo.addEventListener(
    "mousedown",
    () => {
      audio.currentTime = 0;
      audio.play();
    },
    { passive: true }
  );
  logo.addEventListener(
    "touchdown",
    () => {
      audio.currentTime = 0;
      audio.play();
    },
    { passive: true }
  );

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        spectralite.play();
      } else {
        spectralite.pause();
      }
    });
  });

  if (spectralite) {
    observer.observe(spectralite);
  }
};
